import React from "react";
import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Form, Image, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../dashboard.module.scss";
import { Link } from "react-router-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from '../../../../assets/images/nophoto/no_photo.jpg'

import ModalImage from "react-modal-image";

import sponser from "../../../../assets/images/dashboard/sponcer.png";

import Kyctimer from "./Kyctimer";
import DashboardService from "../../../../service/dashboard/Dashboard";
import { AlertMessage } from "../../common";
import { useHistory } from 'react-router-dom'


const Profiles = React.memo((props) => {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const history = useHistory();


  const service = new DashboardService();

  const [state, setState] = useState({
    selectedFile: "",
    url: "",
    showAlert: false,
    alertType: "",
    AlertMessage: "",
    imageUploaded: false,
    loader: false,
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
  });

  console.log("my state", state)

  const onFileChange = (event) => {
    // Update the state
    if (event.target.files[0]) {
      setState((prev) => ({
        ...prev,
        selectedFile: event.target.files[0],
        url: URL.createObjectURL(event.target.files[0]),
      }));
    }
  };


  const submitPayment = () => {

    setState((prevState) => ({
      ...prevState,
      loader: true
    }));

    const FormData = {

    }
    service.UnapprovedDashboardPayment(FormData).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          loader: false,
          notify: {
            show: true,
            type: "success",
            message: "Payment Unsuccessful",
          },
        }));

        localStorage.clear();
        history.push("/logout");
        window.location.reload()


      } else {
        // props.history.push("/logout");
      }
    });
  }

  //remove image
  // const removeImage = () => {
  //   setState((prev) => ({
  //     ...prev,
  //     selectedFile: "",
  //     url: "",
  //     enableFinish: false,
  //     showAlert: false,
  //   }));
  //   if (state.imageUploaded) {
  //     const username = {
  //       user_name: props.userName,
  //     };
  //     service.removeImage(username).then((res) => {});
  //   }
  // };


  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };

  const totalFee = parseFloat(props.package.subscription_fee) + parseFloat(props.package.reg_fee) + parseFloat(props.package.affiliate_fee);

  return (
    <div style={{ width: "80%", paddingTop: "40px" }}
      className={`${currentTheme == "theme-dark"
        ? style.dark_mode_dashboard_pannel_box
        : style.dashboard_pannel_box
        }`}
    >
      <Alert show={state.showAlert} variant={state.alertType} dismissible>
        {state.AlertMessage}
      </Alert>
      {props.lodervalue && (
        <Row>
          <Col xl={12} lg={12} md={12}>
            <div className={style.profile_main_sec}>
              <div className={style.profile_main_img_bx}>
                <SkeletonTheme baseColor="#FF5733" highlightColor="#444">
                  <Skeleton circle={true} height={90} width={90} />
                </SkeletonTheme>
              </div>
              <div className={style.profile_main_txt_sc}>
                <div>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={170} count={1} />
                  </SkeletonTheme>
                </div>
                <div>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} count={1} />
                  </SkeletonTheme>
                </div>
                <div>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton
                      width={120}
                      height={30}
                      count={1}
                      borderRadius={50}
                    />
                  </SkeletonTheme>
                </div>
              </div>
            </div>
          </Col>
        </Row>

      )}
      <Row>
        <Col xl={4} lg={4} md={12}>
          <div className={style.profile_main_sec} style={{ paddingRight: '5px' }}>
            <div className={style.profile_main_img_bx}>
              <img src={props.profile?.user_photo ? props.profile?.user_photo : proPic} alt="No Image" />
            </div>
            <div
              className={`${currentTheme == "theme-dark"
                ? style.dark_mode_profile_main_txt_sc
                : style.profile_main_txt_sc
                }`}
            >
              <h5 style={{ color: "black", fontWeight: "bold" }}>{props.name} </h5>
              <span>User Name : {props.username}</span>
              <span>Email : {props.email}</span>
              {/* <span style={{display : "flex"}}>Payment Method : <h5 style={{fontWeight : "bold"}}>{props.payment_method}</h5> </span>
              <strong>{props.status == "pending" && "Admin verification required"}</strong> */}

            </div>
          </div>
        </Col>
        {/* { props.payment_method == "Bank Transfer" &&  */}

        <Col xl={4} lg={4} md={12}>
          <div style={{ paddingLeft: "0px" }} className={style.profile_main_sec}>
            <div
              className={`${currentTheme == "theme-dark"
                ? style.dark_mode_profile_main_txt_sc
                : style.profile_main_txt_sc
                }`}
              style={{ paddingLeft: "0" }}
            >
              <h5> <b>Packages</b></h5>

              <span>Name : {props.package.name}</span>
              <span>Price : {props.package.subscription_fee}</span>
              <span>Registration Fee : {props.package.reg_fee}</span>
              <span>Affiliate Fee : {props.package.affiliate_fee}</span>
              <span>Total Fee : {totalFee.toFixed(4)}</span>
              <Button
                onClick={submitPayment}
                // disabled={!state.url}
                className={style.uploadButton}
              >
                {state.loader ? (
                  <i className="fa fa-refresh fa-spin" style={{ marginRight: "5px" }} />
                ) : (
                  <i className="fa fa-arrow-circle-o-up"></i>
                )}
                Pay
              </Button>

            </div>
          </div>
        </Col>

        <Col xl={4} lg={4} md={12}>
          <div style={{ paddingLeft: "0px", display: 'flex', alignItems: 'center', border: 'none' }} className={style.profile_main_sec}>
            <div
              className={`${currentTheme == "theme-dark"
                ? style.dark_mode_profile_main_txt_sc
                : style.profile_main_txt_sc
                }`}
              style={{ paddingLeft: "0", display: 'flex', alignItems: 'center' }}
            >
              <Kyctimer
                targetTimestamp={props.countdown}
              />



            </div>
          </div>
        </Col>


        {/* <Col xl={3} lg={3} md={12}>
     <div style={{paddingLeft : "0px"}} className={style.profile_main_sec}>
       <div
              className={`${currentTheme == "theme-dark"
                  ? style.dark_mode_profile_main_txt_sc
                  : style.profile_main_txt_sc
                }`}
                style={{paddingLeft : "0"}}
            >
              <h5>{props.bank_details.bank}</h5>
              
              <span>Account No. : {props.bank_details.account_number}</span>
              <span>Branch : {props.bank_details.branch}</span>
              <span>IFSC : {props.bank_details.ifsc}</span>
             

            </div> 
            </div>
        </Col>
  <Col xl={3} lg={3} md={12}>
     <div style={{paddingLeft : "0px"}} className={style.profile_main_sec}>
            <div
            className={`${currentTheme == "theme-dark"
                ? style.dark_mode_profile_main_txt_sc
                : style.profile_main_txt_sc
              }`}
              style={{paddingLeft : "0"}}
          >
            <h5>{props.package_details.name}</h5>
        
            <span>Price : {props.package_details.price}</span>
            <span>Rank PV : {props.package_details.pv}</span>
           

          </div>
          </div>
        </Col> */}


        {/* } */}
      </Row>
      {props.payment_method == "Bank Transfer" && <Row style={{ marginTop: "30px" }}>
        <Col xl={6} lg={6} md={12} style={{ paddingLeft: "25px" }}>
          <Form.Group>
            <Form.Label className={style.required}>
              {t("Common.selectRecipt")}
            </Form.Label>
            <Form.File onChange={onFileChange} />
            <Form.Text className={style.textdanger}>
              {t("Common.allowTypes")}
            </Form.Text>
          </Form.Group>
          <Button
            onClick={() => { props.uploadImgae(state.selectedFile, props.username, "register") }}
            disabled={!state.url}
            className={style.uploadButton}
          >
            <i className="fa fa-arrow-circle-o-up"></i>
            {t("Button.upload")}
          </Button>
          {/* {state.url && (
        <Form.Group>
          <Image src={state.url} className={style.recieptPreview} />
          <Button className={style.RecieptRemoveButton} onClick={removeImage}>
            <i className="fa fa-times"></i>
            Remove
          </Button>
        </Form.Group>
      )} */}
        </Col>
        {/* { props.payment_method == "Bank Transfer" &&  */}

        {props?.receipt_url && <Col xl={6} lg={6} md={12}>
          <h5 style={{ color: "black", fontWeight: "bold" }}>Uploaded Receipt</h5>
          <div>Please click on image for enlarging</div>
          <div className={style.image_Preview}>
            <ModalImage

              small={props?.receipt_url}
              large={props?.receipt_url}
              alt="Uploaded File"
            />
          </div>
        </Col>
        }
        {/* } */}
      </Row>
      }

      {
        state.notify.show &&
        <>
          <AlertMessage
            message={state.notify.message}
            dismiss={state.notifyDissmissed}
            type={state.notify.type}
            header={state.notify.header}
            show={state.notify.show}
          />
        </>
      }
    </div >
  );
});
export default Profiles;
