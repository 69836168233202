import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import style from "./ewallet.module.scss";
import { CurrencyDecimalFormat } from "../../helper";

function CommissionCalender({loader, ContentData, paginationchange}) {
  const { currentTheme } = useSelector((state) => state.Theme);

  const { t } = useTranslation();
  const Currency = useSelector((state) => state.curr);
  let classnameamount = style.credit;

  const handlePageChange = (inPage) => {
    paginationchange(inPage, ContentData.perPage);
  };
  const handlePerRowsChange = (perPage, inPage) => {
    paginationchange(inPage, perPage);
  };

  const columns = [
    {
      name: t("Common.description").toUpperCase(),
      selector: (row) => row.amount_type,
      width: "30%",
      cell: (row) => (
        <span className={style.description}>
          {t(`ewallet.${row.amount_type}`)} {row.username && ` from ${row.username}`}
        </span>
      ),
    },
    {
      name: t("Common.amount").toUpperCase(),
      selector: (row) => row.amount,

      cell: (row) => (
        <span className={`${classnameamount} ${style.badge}`}>
          {Currency.currentCurr} {CurrencyDecimalFormat(row.amount * Currency.value, Currency.precision)}
        </span>
      ),
    },
    {
      name: t("Common.dated").toUpperCase(),
      selector: (row) => row.date,
    },
  ];
  return (
    <>
      <DataTable
        columns={columns}
        data={ContentData.data}
        highlightOnHover
        progressPending={ContentData?.totalAmount == undefined}
        progressComponent={<div>Loading</div>}
        persistTableHead
        noHeader
        pagination
        paginationServer
        paginationTotalRows={ContentData.totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        noDataComponent={t("Common.noDataRecordsToDisplay")}
        responsive
      />
      {ContentData?.totalAmount ? (
        <div class="pricing-card__price" style={currentTheme === "theme-dark" ? { background: "#0e2b4f" } : {}}>
          <img alt="Total" src={require("../../../assets/images/benefit.svg").default} />${ContentData.totalAmount}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default CommissionCalender;
