import React from 'react';
import { useSelector } from 'react-redux';
import './countdown.css'

const DateTimeDisplay = ({ value, type, isDanger }) => {

  const { currentTheme } = useSelector((state) => state.Theme); 

  return (
    // <div className={isDanger ? 'countdown danger' : 'countdown'} >
    <div className={`${
      isDanger ? 'countdown danger' : 'countdown'
    } ${currentTheme === 'theme-dark' ? 'dark_mode_timer_num' : 'timer_num'}`}>
      <p 
        className={`${currentTheme == "theme-dark"
            ? `dark_mode_timer_num`
            : `timer_num`
          }`}
      >{value}</p>
      <span
            //   className={`${currentTheme == "theme-dark" 
            //   ? `dark_mode_timer_num `
            //   : `timer_num `
            // }`}
      >{type}</span>
    </div>
  );
};

export default DateTimeDisplay;
