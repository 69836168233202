import React, { useEffect, useState } from 'react';
import './Kyctimer.css';

const Kyctimer = ({ targetTimestamp }) => {
  const calculateTimeLeft = () => {
    const difference = targetTimestamp - Date.now();
    let timeLeft = {};

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / (1000 * 60)) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      timeLeft = {
        days,
        hours,
        minutes,
        seconds
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (value) => {
    return value.toString().padStart(2, '0');
  };

  const formatTimeLeft = () => {
    const { days, hours, minutes, seconds } = timeLeft;
    return (
      <div className="countdown">
        <div className="countdown-item">
          <span className="countdown-value">{formatTime(days)}</span>
          <span className="countdown-label" style={{color:'red'}}>Days</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{formatTime(hours)}</span>
          <span className="countdown-label">Hours</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{formatTime(minutes)}</span>
          <span className="countdown-label">Minutes</span>
        </div>
        <div className="countdown-item">
          <span className="countdown-value">{formatTime(seconds)}</span>
          <span className="countdown-label">Seconds</span>
        </div>
      </div>
    );
  };

  return (
    <div className="countdown-timer" >
      {Object.keys(timeLeft).length === 0 ? (
        <div className="timer-expired">Timer expired</div>
      ) : (
        formatTimeLeft()
      )}
    </div>
  );
};
export default Kyctimer;
