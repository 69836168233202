import React, { useState } from "react";
import "./payLater.css";
import { Button, Modal } from "bootstrap";

function PayLater(props) {
  const [paySpin, setpaySpin] = useState();

  const buyNow = () => {
    props.payStripe(true);
    setpaySpin(true);
  };

  const reactivationtotal = (parseFloat(props.amount)) + (parseFloat(props.fee["reactivationFee"]));
  console.log("🚀 ~ file: PayLater.jsx:14 ~ PayLater ~ reactivationtotal:", reactivationtotal)
  // const reactivationtotal = parseFloat(props.amount);

  return (
    <>
      <div class="page11">
        <div class="main11">
          <button className="paylater-cancel-button"
            onClick={props.handleClose}
          >
            <i style={{ color: "#606060" }} class="fa fa-times" aria-hidden="true"></i>
          </button>
          <div class="product-container1">
            <h2>{props.name}</h2>
            <img src={props.images} alt="" />
            {/* <div style={{backgroundImage:`url(${props.images})`, width: '100px', height: '100px'}}></div> */}
          </div>
          <div className="paylater-prices"
            style={{
              position: "absolute",
              color: "#fff",
              left: "20rem",
              top: "3rem",
              lineHeight: "35px",
            }}
          >
            {/* {props.desc} */}


            {props.fee["reactivationFee"] || props.fee["reactivationFee"] == 0 ? (
              <div>
                Reactivation Fee: <b>{props.fee["reactivationFee"] ? props.fee["reactivationFee"] : 0.0}</b>
              </div>
            ) : (
              <>
                <div>
                  Subscription Fee: <b>{parseFloat(props.amount).toFixed(2)}</b>
                </div>

                <div>
                  Admin Cost: <b>{props.fee["Admin Cost"] ? props.fee["Admin Cost"] : 0.0}</b>
                </div>

                <div>
                  Affiliate Fee: <b>{props.fee["Affiliate Fee"] ? props.fee["Affiliate Fee"] : 0.0}</b>
                </div>
              </>
            )}

            <div className="paylater-total-price" style={{ marginTop: "14px", fontSize: "1.4em" }}>
              Total:{" "}
              <b style={{ color: "#beebfa" }}>
                {props.fee["reactivationFee"] || props.fee["reactivationFee"] == 0
                  ? reactivationtotal
                    ? reactivationtotal.toFixed(2)
                    : 0.0
                  : props.total 
                  ? props.total
                  : 0.0}
                $
              </b>
            </div>
          </div>
          <button className="buyNow" onClick={buyNow}>
            {/* <button className="buyNow" onClick={handleShow}> */}
            PAY NOW
            {paySpin ? <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i> : <i class="fa fa-angle-right"></i>}
          </button>
          <div class="price-container">
            <strong className="paylater-product-price" /* style={{ fontSize: "1.8rem", color }} */>{parseFloat(props.amount).toFixed(2)} $</strong>
            {/* <small>Inc. shipping & tax</small> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PayLater;
