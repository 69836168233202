import React from "react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
//components
import PageTitle from "../../components/common/pageTitle";
import MainContent from "../../components/dashboard/MainContent";
//service
import DashboardService from "../../../service/dashboard/Dashboard";
import AppinfoService from "../../../service/common/Appinfo";
import Spinner from "../../shared/Spinner";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import { useSelector } from "react-redux";
import { useParams } from "react-router";


import dummydata from "./dashboard.json";
import { AlertMessage } from "../../components/common";
import { Button, Modal } from "react-bootstrap";
import UpdateWallet from "./UpdateWallet";

const Home = (props) => {
  const noWalletNotification = localStorage.getItem('noWalletNotification') || false;
  const cplan = useSelector((state) => state.ProjectConfig.mlmPlan);
  const curr = useSelector(state => state.curr);
  const history = useHistory();
  const params = useParams();

  const [payRes, setPayRes] = useState(false)
  const [show, setShow] = useState(false);


  const [state, setState] = useState({
    payAmount: '',
    payAddress:'',
    memberStatus: false,
    tiles_loading: true,
    joining_loading: true,
    earnings_loading: true,
    alert: {
      show: false,
      varient: "",
      message: "",
    },
    modal: {
      title: "",
      show: false,
      data: {},
      id: "",
    },
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
    continue: false,
    visitorid: "",
    invoiceLink: '',
    walletNotification: false
  });

  const service = new DashboardService();
  const commonService = new AppinfoService();
  const { t } = props;


  function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const service = new DashboardService();
    const { state } = props.location;


    if (localStorage.getItem("visitorid")) {
      setState((prevState) => ({
        ...prevState,
        modal: {
          title: "Enter OTP sent to your email",
          show: true,
          data: {},
          id: "",
        },
        // visitorid : res.visitor_id,
        continue: true,
      }));
    }

    var userEmail = getCookie("visitor");
    if (state) {
      if (state.error) {
        setState((prevstate) => ({
          ...prevstate,
          alert: {
            show: true,
            varient: "danger",
            message: "validation." + state.message,
          },
        }));
      }
    }
    commonService.checkDemoUsers().then((res) => {
      if (res.data.is_preset_demo && userEmail == null) {
        setState((prevState) => ({
          ...prevState,
          modal: {
            title: "Please fill in your details to continue",
            show: true,
            data: {},
            id: "",
          },
        }));
      } else if(res.data.addWalletNotification) {
        setState((prevstate) => ({
          ...prevstate,
          walletNotification: true
        }))
      }
    });
    getDashboard();
    // submitForm();

    // service.DashboardLoad().then((res) => {
    //   if (res.status) {
    //     setState((prevState) => ({
    //       ...prevState,
    //       loading: false,
    //       ...res.data,
    //     }));
    //   } else {
    //     props.history.push("/logout");
    //   }
    // });
    // },[])

    if (params.status == "1") {
      setState({
        ...state,
        notify: {
          show: true,
          type: "success",
          message: t("Common.successmsg"),
        },
      });
    } else if (params.status == "0") {
      setState({
        ...state,
        notify: {
          show: true,
          type: "danger",
          message: t("Common.failmsg"),
        },
      });
    }

  }, []);

  const FiterGraph = (range) => {
    service.JoiningGraphFilter(range).then((res) => {
      if (res.status) {
        setState((prev) => ({
          ...prev,
          joining_graph_data_new: res.data.joining_graph_data_new,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const TilesFitler = (type, range) => {
    service.TileFilter(type, range).then((res) => {
      if (res.status) {
        let newData = state.tiles.map((object) => {
          if (object.text === type) {
            return res.data;
          } else {
            return object;
          }
        });
        setState((prevState) => ({
          ...prevState,
          tiles: newData,
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  const closeAlert = () => {
    setState((prevState) => ({
      ...prevState,
      alert: {
        show: false,
        message: "",
        varient: "",
      },
    }));
  };

  const getDashboard = async () => {
    setState((prevState) => ({
      ...prevState,
      tiles_loading: false,
      ...dummydata,
    }));
    const reponse = await Promise.all([service.dashboardTiles(), service.dashboardJoining(), service.dashboardEarnings()])

    if (reponse[0].status) {
      setState((prevState) => ({
        ...prevState,
        tiles_loading: false,
        ...reponse[0].data,
      }));
    }
    if (reponse[1].status) {
      setState((prevState) => ({
        ...prevState,
        joining_loading: false,
        ...reponse[1].data,
      }));
    }
    if (reponse[2].status) {
      setState((prevState) => ({
        ...prevState,
        earnings_loading: false,
        ...reponse[2].data,
      }));

    }

  }



  const submitForm = () => {
    service.PayLater().then((res) => {
      if (res.status) {

        setPayRes(true)
        window.location.href = res.invoice_link

        // setShow(true)


        // setState({
        //   ...state,
        //   payAmount: res.amount,
        //   payAddress: res.pay_address,
          
        // });

      } else {

      }
    })
  }

  const dissmissNotify = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        ...prev.notify,
        show: false,
      },
    }));
  };


  const handleClose = () => setShow(false);


  //const reponse =  await Promise.all([service.dashboardTiles(),service.dashboardJoining(),service.dashboardEarnings()])
  return (
    <div>
      {/* <Spinner /> */}

      {/* {state.loading ? (
        
      ) : ( */}
      <React.Suspense fallback={<Spinner />}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.overview")}
          </title>
        </Helmet>
        {/* <PageTitle title="overview" buttonOn={false} /> */}
        <MainContent
          {...state}
          setstate={setState}
          filterChange={TilesFitler}
          chartfilterChange={FiterGraph}
          alertClose={closeAlert}
          style={{ marginTop: "-2%" }}
          lodervalue={state.loading}
          tiles_loading={state.tiles_loading}
          joining_loading={state.joining_loading}
          earnings_loading={state.earnings_loading}
          submitForm={submitForm}
          payRes={payRes}
          setPayRes={setPayRes}

        />

      </React.Suspense>
      {/* )} */}
      {state.notify.show && (
        <AlertMessage
          show={state.notify.show}
          message={state.notify.message}
          dismiss={dissmissNotify}
          type={state.notify.type}
          header={state.notify.header}
        />
      )}


      {/* <Modal centered show={show} onHide={handleClose}>
        <Modal.Header style={{background:'#fff'}} closeButton>
          <Modal.Title style={{background:'#fff'}}>Please Complete Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{background:'#fff'}}>
          <div>Amount: {state.payAmount}</div>
          <div>Address: <a href={state.payAddress}>{state.payAddress}</a> </div>
        </Modal.Body>
        <Modal.Footer style={{background:'#fff'}}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

      {false && (state.walletNotification && !noWalletNotification) && (<UpdateWallet setState={setState}/>)}
    </div>
  );
};
export default withTranslation()(withRouter(Home));
