import companyName from '../store/mobxStore/companyName';

var COMPANY_NAME = "";
var BASE_URL = "";
var IMG_URL = "";
var LEAD_URL = "";
var API_KEY = "";
var DEFAULT_KEY = "";
var BASE = "";

let REACT_APP_STATUS = "LIVE"

if (REACT_APP_STATUS === "LIVE") {
    //  L I V E
    COMPANY_NAME = companyName.companyName ?? 'Company Name';
    BASE_URL = "https://admin.vivamagnifica.com/node_api/web/";
    IMG_URL = "https://admin.vivamagnifica.com/uploads/images/";
    LEAD_URL = "https://admin.vivamagnifica.com/node_api/web/";
    API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
    // DEFAULT_KEY ='903's
    DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
    BASE = "https://admin.vivamagnifica.com/";

} if (REACT_APP_STATUS === "DEMO") { 
    // D E M O
    COMPANY_NAME = companyName.companyName ?? 'Company Name';
    BASE_URL = "http://162.19.146.134:1198/node_api/web/";
    IMG_URL = "http://162.19.146.134:1198/uploads/images/";
    LEAD_URL = "http://162.19.146.134:1198/node_api/web/";
    API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
    // DEFAULT_KEY ='903's
    DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
    BASE = "http://162.19.146.134:1198/";

} if (REACT_APP_STATUS === "LOCAL"){
     //  L O C A L
     COMPANY_NAME = companyName.companyName ?? 'Company Name';
     BASE_URL = "http://192.168.12.8:5039/node_api/web/";
     IMG_URL = "http://192.168.12.8:5000/uploads/images/";
     LEAD_URL = "http://192.168.12.8:5000/node_api/web/";
     API_KEY = localStorage.getItem('apiKey'); //binaryaddon;
     // DEFAULT_KEY ='903's
     DEFAULT_KEY = '387245a5a919ea2071cc406b10b89d4685e5cc8e'
     BASE = "http://192.168.12.88:5000/";
}

export {
    COMPANY_NAME,
    BASE_URL,
    IMG_URL,
    API_KEY,
    BASE,
    DEFAULT_KEY,
    LEAD_URL
}
