import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { Header, Banner, Plans, About, Contact, Policy,Footer } from '../../components/replica';
import { useParams, useHistory } from 'react-router-dom';
import ReplicaSiteService from '../../../service/replica/replica';
import { getTableContainerUtilityClass } from '@mui/material';






function Privacy() {
    const path = useParams();
    const history = useHistory();
    const { user_name, admin } = useParams();

    const service = new ReplicaSiteService();
    const [state, setState] = useState({
        header_data: [],
        policy_data: [],
        banner_data: [],


    })

    const replicaData = (languageId) => {
        let admin_username = admin ? admin : ""
        service.getApi(admin_username).then((res) => {
            if (res.status) {
                localStorage.setItem('apiKey', res.data.key);
                service.getHeader(user_name, languageId).then((res) => {
                    if (res.status) {
                        setState((prevState) => ({
                            ...prevState,
                            header_data: res.data,
                            profile_pic: res.data.image,
                            personal_info: res.data.personalInfo,
                            loding: false,
                        }));
                    } else {
                        //alert("Invalid Url");
                    }
                    //   app.Appinfo().then((res) => {
                    //     if (res.status) {
                    //       setState((prevState) => ({
                    //         ...prevState,
                    //         logo: res.data.company_info.login_logo,
                    //       }));
                    //     }
                    //   });
                });
                getBanner();
                getPolicy();
            } else {
                alert("Invalid Admin user name");
            }
        });
    }


    useEffect(() => {
       const langId = localStorage.getItem('langId');

        service.getHeader(path.user_name, langId).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    header_data: res.data

                }));
            } else {
                history.push('/login');
            }

        })
        getBanner();
        getPolicy();


    }, [])

    const getBanner = () => {
        service.getBanner(path.user_name).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    banner_data: res.data

                }));
            } else {
                history.push('/login');
            }

        })

    }
    const getPolicy = () => {
    const langId = localStorage.getItem('langId');
        service.getPolicy(path.user_name, langId).then((res) => {
            if (res.status) {
                setState(prevState => ({
                    ...prevState,
                    policy_data: res.data

                }));
            } else {
                alert("Invalid Url")
            }

        })

    }

    const langId = (id) => {

        console.log('langId', id);
        setState((prevState) => ({
            ...prevState,
            language: id
        }));

        replicaData(id)

    }


    return (
        <div className="h-100">
            <>


          

            {state.header_data &&
                    <Header
                        data={state.header_data}
                        user_name={path.user_name}
                        showmenu={false}
                        langId={langId}
                        terms={true}
                    />

                }

                {state.policy_data &&
                    <Policy
                        data={state}
                        policy_data={state.policy_data}
                    />
                }

                {state.policy_data &&
                    <Footer
                        data={state}
                        user_name={path.user_name}
                    />
                }



            </>




        </div>
    );

}
export default withTranslation()(withRouter(Privacy))