import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DashboardService from "../../../service/dashboard/Dashboard";
import "./recharge.css";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../../components/common";

function RechargeView() {
  const { t } = useTranslation();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const service = new DashboardService();

  const amountChangeHandler = (e) => {
    if (e.target.value.length > 0 && Number.isNaN(e.target.value[e.target.value.length - 1] * 1)) return;
    setAmount(e.target.value);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setLoading(true);
    service.PayLater({ amount: amount, type: "recharge" }).then((res) => {
      if (res.status) {
        setLoading(false);
        window.location.href = res.invoice_link;
      }
    });
  };
  return (
    <>
      <PageTitle title="recharge" buttonOn={false} />
      <div
        style={{
          minHeight: "90vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "0 10px",
        }}
      >
        <Card
          style={{
            padding: "0 1rem",
            margin: "0 5px",
            width: "60%",
            height: "20rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <form
            onSubmit={submitForm}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              width: "70%",
            }}
          >
            <p>Amount</p>
            <input
              required
              placeholder="$"
              style={{ width: "100%", height: "2.5rem", padding: "0 5px", paddingLeft: "8px" }}
              type="text"
              onChange={amountChangeHandler}
              value={amount}
            />{" "}
            <br />
            <div>
              {loading ? <div></div> : null}
              <button className="recharge-submit-button" type="submit">
                <span>SUBMIT{loading ? <i class="fa fa-spinner fa-spin fa-fw"></i> : null}</span>
              </button>
            </div>
          </form>
        </Card>
      </div>
    </>
  );
}

export default RechargeView;
