import React, { useEffect, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import style from "./dashboard.module.scss";
import Profiles from "./profiles";
import Tile from "./Tile";
import EarningsTab from "./EarningsTab";
import TeamPerformance from "./TeamPerformance";
// import TeamPerformanceNew from './TeamPerformanceNew';
import Members from "./Members";
import Charts from "./charts";
import Ranks from "./Ranks";

import DashboardModel from "./DashboardModal";
import { useTranslation } from "react-i18next";

import AddForm from "./AddForm";
import VerificationForm from "./VerificationForm";

import { Dashboard } from "@mui/icons-material";
import { Stripe } from "../Payments";
import PayLater from "./PayLater";
import { useHistory } from "react-router-dom";


function MainContent(props) {
  const [show, setShow] = useState(false);
  const [isClicked, clicked] = useState(false);

  const [payNow, setPayNow] = useState(false);

  const [demoPay, setDemoPay] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const history = useHistory();
  const handleRechargeRedirect = () => {
    history.push("/recharge");
  };


  const [show2, setShow2] = useState(false);
  const [spin, setSpin] = useState(false);

  const handleClose2 = () => { setShow2(false) };
  const handleShow2 = () => {
    // setShow2(true)
    props.submitForm()
  };


  const [mail, setMail] = useState('');
  console.log('mail', mail);
  const submitPay = () => {
    // handleClose2();
    // handleClose();
    setSpin(true)
    // props.submitForm(mail)
    // history.push(invoiceLink)
  }

  useEffect(() => { setMail(props.profile?.email) }, [props.profile?.email])
  useEffect(() => {
    setSpin(false)
    handleClose2();
    handleClose();
    props.setPayRes(false)
  }, [props.payRes])

  // useEffect(()=>handleShow(true),[props.profile?.payLater])



  return (
    <Container fluid className={`p-3`} style={props?.style}>
      <Modal
        style={{ background: 'rgba(1, 1, 2, 0.6)' }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size='md'
        show={show2} onHide={handleClose2}>
        {/* <Modal.Header closeButton style={{background:'#fff'}}>
          <Modal.Title>Now Payment</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body style={{background:'#fff'}}> 
        <label htmlFor="">E-mail</label>
        <input type="text" style={{width:'100%'}} />
         </Modal.Body> */}

        <Modal.Body >
          <div class="container33">
            <div class="card33">
              <div className='form33'>
                <label htmlFor="" className='ml-2'>E-mail</label>
                <input className='input33' onChange={(e) => setMail(e.target.value)} value={mail} type="text" placeholder="example@gmail.com" />

                <div class="buttons33">
                  <button onClick={handleClose2} class="register-button33">Close</button>
                  <button onClick={submitPay} class="login-button33">{spin ? <i class="fa fa-spinner fa-pulse fa-fw"></i> : 'Send'}</button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        {/* <Modal.Footer style={{background:'#fff'}}>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose2}>
            Send
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Modal show={show} onHide={handleClose} size="lg">
        <PayLater
          handleClose={handleClose}
          // payStripe={(data) => data && setDemoPay(true)}
          payStripe={handleShow2}
          name={props.profile && props.profile?.payLater?.name}
          amount={props.profile && props.profile?.payLater?.amount}
          images={props.profile && props.profile?.payLater?.images}
          desc={props.profile && props.profile?.payLater?.description}
          fee={props.profile && props.profile?.payLater?.fee}
          total={props.profile?.payLater?.fee.Total}
          payRes={props.payRes}
        />
      </Modal>
      {/* <NewProfile profile={props.profile}/> */}
      <div className={style.left_navigation_fold}>
        <div className={style.main_contant_sec}>
          <section className={style.contant_sec}>
            <div className={style.cntr_main_cnt_sc}>
              <div className={style.dashboard_main_cnt}>
                {/* <div className={style.dashboard_pannel_box} style={{ border: '2px solid #F2C200', textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
               
                   <span style={{ marginRight: '5px', paddingTop: '15px' }}>
                     To get started and become an integral part of our business, please proceed with your payment.
                   </span>
                  <button className={style.payButtons} onClick={() => handleShow(true)}>Pay</button>
                 </div> */}

                {props.profile?.payLater?.status ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // bottom: "0",
                      // height:'60px'

                    }}
                  >
                    <div
                      style={{
                        position: "fixed",
                        bottom: "0",
                        // top: "0px",
                        zIndex: "99",
                        minHeight: "50px",
                        background:
                          "linear-gradient(90deg, rgb(134, 11, 84) 0%, rgb(119, 42, 144) 100%)",
                        marginBottom: "10px",
                        padding: "8px",
                        borderRadius: "10px",
                        display: "flex",
                        alignItems: "center",
                        // fontSize:"10px",
                      }}
                      className={style.payRes}
                    >
                      <div>
                        <span style={{ color: "#fff", fontWeight: '900' }}>
                          {props.profile?.payLater?.warning}
                        </span>
                      </div>
                      <div>
                        <button
                          onClick={
                            props.profile?.payLater?.buttonName == "Pay" || props.profile?.payLater?.buttonName == "Renew"
                              ? () => handleShow(true)
                              : handleRechargeRedirect
                          }
                          onMouseEnter={() => clicked(true)}
                          onMouseLeave={() => clicked(false)}
                          style={{
                            background: isClicked ? "#EFEEF4" : "#fff",
                            width: "85px",
                            maxWidth: "85px",
                            height: "34px",
                            maxHeight: "34px",
                            borderRadius: "5px",
                            // paddingTop: "3px",
                            marginLeft: "20px",
                            cursor: "pointer",
                            fontWeight: 'bolder',
                            fontSize: props.profile?.payLater?.buttonName == "Pay" ? "25px" : props.profile?.payLater?.buttonName == "Recharge" ? "13px" :"18px",
                            paddingLeft:"2px",
                            paddingRight:'2px'
             

                          }}
                          className={style.rechargeSize}
                        >
                          <span style={{ color: '#76298F', fontFamily: 'sans-serif' }}>
                            {props.profile?.payLater?.buttonName.toUpperCase()}
                            
                            </span>
                        </button>
                      </div>
                    </div>
                  </div>
                ) : null}
                {props.profile && (
                  <Profiles
                    profile={props.profile}
                    lodervalue={props.tiles_loading}
                  />
                )}
                <Tile
                  pvData={props.extra_data}
                  SponserData={props.sponser_details}
                  Tiles={props.tiles}
                  filterChange={props.filterChange}
                  lodervalue={props.tiles_loading}
                />
                <div className={style.dash_member_join_rank_sec}>
                  <>
                    {props.new_members && (
                      <Members
                        memberList={props.new_members}
                        lodervalue={props.joining_loading}
                      />
                    )}
                    {props.joining_graph_data_new && (
                      <Charts
                        data={props.joining_graph_data_new}
                        filterChange={props.chartfilterChange}
                        lodervalue={props.joining_loading}
                      />
                    )}

                    {props.rank && <Ranks rank={props.rank} />}
                  </>
                </div>
                <div className={style.dashbord_earning_expence}>
                  {props.team_perfomance && (
                    <TeamPerformance
                      topEarners={
                        props.team_perfomance &&
                        props.team_perfomance.top_earners
                      }
                      topRecruters={
                        props.team_perfomance &&
                        props.team_perfomance.top_recruiters
                      }
                      packageoverviews={
                        props.team_perfomance &&
                        props.team_perfomance.package_overview
                      }
                      rankOverviews={
                        props.team_perfomance &&
                        props.team_perfomance.rank_overview
                      }
                      lodervalue={props.earnings_loading}
                      data={props}
                    />
                  )}

                  {props.earnings_nd_expenses && (
                    <EarningsTab
                      lodervalue={props.earnings_loading}
                      income={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.incomes
                      }
                      expenses={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.expenses
                      }
                      payoutstatus={
                        props.earnings_nd_expenses &&
                        props.earnings_nd_expenses.payout_statuses
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <DashboardModel show={props.modal?.show} title={props.modal?.title}>
        {!props.continue && <AddForm {...props}></AddForm>}
        {props.continue && <VerificationForm {...props}></VerificationForm>}
      </DashboardModel>
    </Container>
  );
}

export default MainContent;
