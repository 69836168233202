import { useEffect, useState } from 'react';
// import moment from 'moment-timezone';


const useCountdown = (props) => {

  const now = new Date();

  const daysToSunday = 7 - now.getDay();

  const sunday = new Date(now.getFullYear(), now.getMonth(), now.getDate() + daysToSunday, 0, 0, 0);
  sunday.setHours(5, 48, 0, 0);
  // const targetDate = sunday.getTime();
  const targetDate = props;


  const [countDown, setCountDown] = useState(
    targetDate - now.getTime()
  );


  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(targetDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [targetDate]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {

  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};

export { useCountdown };
